require('./bootstrap')
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'select2-component/dist/select2.min.css'
import { Select2 } from "select2-vue-component";

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = sessionStorage.getItem('token')
        if (token && token !== 'null') {
            next();
        } else {
            next('/')
            sessionStorage.removeItem("token");
        }
    } else {
        next()
    }
})
const app = createApp(App)

app.component('select2', Select2);
app.config.globalProperties.$apiurl = "http://103.145.178.150/inventory/backend/inventory/api/"
app.use(router)
app.mount('#app')