import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    { 
        path: '/', 
        component: () => import('./pages/login.vue') 
    },
    { 
        path: '/suratjalan', 
        component: () => import('./pages/menu/print/cetak_suratjalan.vue') 
    },
    {
        path:'/cetaksuratjalan/:id',
        name:'cetak-surat-jalan',
        component: () => import('./pages/menu/print/cetak_suratjalan.vue')
    },
    {
        path:'/cetaktrialbalance',
        name:'cetak-trial-balance',
        component: () => import('./pages/menu/print/cetak_trial_balance.vue')
    },
    {
        path:'/admin',
        component:() => import('./layout/AdminLayout.vue'),
        meta: {
            requiresAuth: true
        },
        children:[
            {
                path:'/home',
                component:() => import('./pages/menu/dashboard.vue')
            },
            {
                path:'/detail-barang-alias/:id',
                name:'detail-barangalias',
                // component:() => import('./pages/menu/barang_alias/v_read.vue')
                component:() => import('./pages/menu/master_data/barang_alias/v_read.vue')
            },
            { 
                path: '/add-barang-alias/:id', 
                name:'add-barang-alias',
                component: () => import('./pages/menu/master_data/barang_alias/v_add.vue') 
            },
            { 
                path: '/edit-barang-alias/:id', 
                component: () => import('./pages/menu/master_data/barang_alias/v_edit.vue') 
            },
            {
                path:'/barang',
                name:'barang',
                // component:() => import('./pages/menu/barang/v_read.vue')
                component:() => import('./pages/menu/master_data/barang/v_read.vue')
            },
            { 
                path: '/add-barang', 
                component: () => import('./pages/menu/master_data/barang/v_add.vue') 
            },
            { 
                path: '/edit-barang/:id', 
                component: () => import('./pages/menu/master_data/barang/v_edit.vue') 
            },
            {
                path:'/supplier',
                name:'supplier',
                // component:() => import('./pages/menu/supplier/v_read.vue')
                component:() => import('./pages/menu/master_data/supplier/v_read.vue')
            },
            { 
                path: '/add-supplier', 
                component: () => import('./pages/menu/master_data/supplier/v_add.vue') 
            },
            { 
                path: '/edit-supplier/:id', 
                component: () => import('./pages/menu/master_data/supplier/v_edit.vue') 
            },
            {
                path:'/part',
                name:'part',
                component:() => import('./pages/menu/master_data/part/v_read.vue')
            },
            { 
                path: '/add-part', 
                component: () => import('./pages/menu/master_data/part/v_add.vue') 
            },
            { 
                path: '/edit-part/:id', 
                component: () => import('./pages/menu/master_data/part/v_edit.vue') 
            },
            { 
                path: '/buyer',
                name:'buyer',
                // component: () => import('./pages/menu/buyer/v_read.vue') 
                component: () => import('./pages/menu/master_data/buyer/v_read.vue') 
            },
            { 
                path: '/add-buyer',
                name:'addbuyer',
                component: () => import('./pages/menu/master_data/buyer/v_add.vue') 
            },
            { 
                path: '/edit-buyer/:id',
                name:'editbuyer',
                component: () => import('./pages/menu/master_data/buyer/v_edit.vue') 
            },
            { 
                path: '/kategori-barang',
                name:'kategoribarang',
                // component: () => import('./pages/menu/kategori_barang/v_read.vue') 
                component: () => import('./pages/menu/master_data/kategori_barang/v_read.vue') 
            },
            { 
                path: '/add-kategori-barang',
                name:'addkategori-barang',
                component: () => import('./pages/menu/master_data/kategori_barang/v_add.vue') 
            },
            { 
                path: '/edit-kategori-barang/:id',
                name:'editkategori-barang',
                component: () => import('./pages/menu/master_data/kategori_barang/v_edit.vue') 
            },
            { 
                path: '/daftar-proses',
                name:'daftarproses',
                // component: () => import('./pages/menu/proses/v_read.vue') 
                component: () => import('./pages/menu/master_data/proses/v_read.vue') 
            },
            { 
                path: '/add-daftar-proses',
                name:'adddaftar-proses',
                component: () => import('./pages/menu/master_data/proses/v_add.vue') 
            },
            { 
                path: '/edit-daftar-proses/:id',
                name:'editdaftar-proses',
                component: () => import('./pages/menu/master_data/proses/v_edit.vue') 
            },
            { 
                path: '/daftar-order',
                name:'daftarorder',
                component: () => import('./pages/menu/order_h/v_read.vue') 
            },
            { 
                path: '/add-daftar-order',
                name:'adddaftar-order',
                component: () => import('./pages/menu/order_h/v_add.vue') 
            },
            { 
                path: '/edit-daftar-order/:id',
                name:'editdaftar-order',
                component: () => import('./pages/menu/order_h/v_edit.vue') 
            },
            {
                path:'/add-order-detail/:id',
                name:'adddetail-order',
                component:() => import('./pages/menu/order_d/v_add.vue')
            },
            { 
                path: '/detail-order/:id', 
                name:'detailorder',
                component: () => import('./pages/menu/order_d/v_read.vue') 
            },
            { 
                path: '/edit-order-detail/:id', 
                name:'edit-detailorder',
                component: () => import('./pages/menu/order_d/v_edit.vue') 
            },
            {
                path:'/add-orderproses-detail/:id',
                name:'adddetail-orderproses',
                component:() => import('./pages/menu/order_proses_d/v_add.vue')
            },
            { 
                path: '/detail-orderproses/:id', 
                name:'detailorderprosesview',
                component: () => import('./pages/menu/order_proses_d/v_read.vue') 
            },
            { 
                path: '/edit-orderproses-detail/:id', 
                name:'edit-detailorderproses',
                component: () => import('./pages/menu/order_proses_d/v_edit.vue') 
            },
            {
                path:'/add-ordercomponent-detail/:id',
                name:'adddetail-ordercomponent',
                component:() => import('./pages/menu/order_component_d/v_add.vue')
            },
            { 
                path: '/detail-ordercomponent/:id', 
                name:'detailordercomponentview',
                component: () => import('./pages/menu/order_component_d/v_read.vue') 
            },
            { 
                path: '/edit-ordercomponent-detail/:id', 
                name:'edit-detailordercomponent',
                component: () => import('./pages/menu/order_component_d/v_edit.vue') 
            },
            {
                path:'/add-orderbom-detail/:id',
                name:'adddetail-orderbom',
                component:() => import('./pages/menu/order_bom_d/v_add.vue')
            },
            { 
                path: '/detail-orderbom/:id', 
                name:'detailorderbomview',
                component: () => import('./pages/menu/order_bom_d/v_read.vue') 
            },
            { 
                path: '/edit-orderbom-detail/:id', 
                name:'edit-detailorderbom',
                component: () => import('./pages/menu/order_bom_d/v_edit.vue') 
            },
            { 
                path: '/po',
                name:'po',
                // component: () => import('./pages/menu/purchase_order/v_read.vue') 
                component: () => import('./pages/menu/purchase/purchase_order/v_read.vue') 
            },
            { 
                path: '/add-po',
                name:'addpo',
                component: () => import('./pages/menu/purchase/purchase_order/v_add.vue') 
            },
            { 
                path: '/edit-po/:id',
                name:'editpo',
                component: () => import('./pages/menu/purchase/purchase_order/v_edit.vue') 
            },
            { 
                path: '/list-po-detail/:id',
                name:'listpodetail',
                component: () => import('./pages/menu/purchase/purchase_order_d/v_read.vue') 
            },
            { 
                path: '/add-po-detail/:nopo',
                name:'addpodetail',
                component: () => import('./pages/menu/purchase/purchase_order_d/v_read2.vue') 
            },
            { 
                path: '/edit-po-detail/:id',
                name:'editpodetail',
                component: () => import('./pages/menu/purchase/purchase_order_d/v_edit.vue') 
            },
            { 
                path: '/coa',
                name:'coa',
                component: () => import('./pages/menu/accounting/coa/v_read.vue') 
            },
            { 
                path: '/edit-coa/:id',
                name:'editcoa',
                component: () => import('./pages/menu/accounting/coa/v_edit.vue') 
            },
            { 
                path: '/add-coa',
                name:'addcoa',
                component: () => import('./pages/menu/accounting/coa/v_add.vue') 
            },
            { 
                path: '/produk',
                name:'produkheader',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_h/v_read.vue') 
            },
            { 
                path: '/edit-produk/:id',
                name:'editprodukheader',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_h/v_edit.vue') 
            },
            { 
                path: '/add-produk',
                name:'addproduk',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_h/v_add.vue') 
            },
            { 
                path: '/produk-detail/:id',
                name:'produkdetail',
                // component: () => import('./pages/menu/produk_d/v_read.vue') 
                // component: () => import('./pages/menu/produk_d/v_readdetail.vue') 
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_d/v_readdetail2.vue') 
            },
            { 
                path: '/edit-produk-detail/:id',
                name:'editprodukdetail',
                // component: () => import('./pages/menu/produk_d/v_edit.vue') 
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_d/v_editdetail.vue') 
            },
            { 
                path: '/add-produk-proses',
                name:'addprodukproses',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d/v_add.vue') 
            },
            { 
                path: '/produk-prosesdetail/:id',
                name:'produkprosesdetail',
                // component: () => import('./pages/menu/produk_proses_d/v_read.vue') 
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d/v_readdetail.vue') 
            },
            { 
                path: '/edit-produkproses-detail/:id',
                name:'editprodukprose-detail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d/v_edit.vue') 
            },
            { 
                path: '/addbom-produkproses-detail/:id',
                name:'addbomprodukprosesdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_add.vue') 
            },
            { 
                path: '/edit-produkprosesbom-detail/:id',
                name:'editprodukprosesbomdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_edit.vue') 
            },
            { 
                path: '/listbom-produkproses-detail/:id',
                name:'listbomprodukprosesdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_read.vue') 
            },
            { 
                path: '/buyer-order',
                name:'buyerorder',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order/v_read.vue') 
            },
            { 
                path: '/addbuyer-order',
                name:'addbuyerorder',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order/v_add.vue') 
            },
            { 
                path: '/editbuyer-order/:id',
                name:'editbuyerorder',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order/v_edit.vue') 
            },
            { 
                path: '/add-schedule-buyer/:id',
                name:'addschedulebuyer',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order_schedule/v_add.vue') 
            },
            { 
                path: '/listall-daftar-proses/:id',
                name:'listalldaftarproses',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order_schedule/v_readproses.vue') 
            },
            { 
                path: '/listbuyer-schedule/:id',
                name:'listbuyerschedule',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order_schedule/v_readschedule.vue') 
            },
            { 
                path: '/edit-schedule-buyer/:id',
                name:'editschedulebuyer',
                component: () => import('./pages/menu/product_dan_orderbuyer/buyer_order_schedule/v_edit.vue') 
            },
            { 
                path: '/tpb23',
                name:'jenistpb23',
                component: () => import('./pages/menu/tpb/tpb23/v_read.vue') 
            },
            { 
                path: '/add-penerimaanbarang/:id',
                name:'addpenerimaanbarang',
                component: () => import('./pages/menu/warehouse/penerimaan_h/v_add.vue') 
            },
            { 
                path: '/penerimaan',
                name:'penerimaanheader',
                component: () => import('./pages/menu/warehouse/penerimaan_h/v_read.vue') 
            },
            { 
                path: '/edit-penerimaan/:id',
                name:'editpenerimaanheader',
                component: () => import('./pages/menu/warehouse/penerimaan_h/v_edit.vue') 
            },
            { 
                path: '/add-penerimaandetail/:id/:nobc',
                name:'addpenerimaandetail',
                component: () => import('./pages/menu/warehouse/penerimaan_d/v_add.vue') 
            },
            { 
                path: '/edit-penerimaandetail/:id',
                name:'editpenerimaandetail',
                component: () => import('./pages/menu/warehouse/penerimaan_d/v_edit.vue') 
            },
            { 
                path: '/list-penerimaandetail/:id',
                name:'listpenerimaandetail',
                component: () => import('./pages/menu/warehouse/penerimaan_d/v_read.vue') 
            },
            { 
                path: '/jurnalumum',
                name:'jurnalumumheader',
                component: () => import('./pages/menu/accounting/jurnal_umum_h/v_read.vue') 
            },
            { 
                path: '/add-jurnalumum',
                name:'addjurnalumumheader',
                component: () => import('./pages/menu/accounting/jurnal_umum_h/v_add.vue') 
            },
            { 
                path: '/edit-jurnalumum/:id',
                name:'editjurnalumumheader',
                component: () => import('./pages/menu/accounting/jurnal_umum_h/v_edit.vue') 
            },
            { 
                path: '/list-jurnalumum/:id',
                name:'listjurnalumumdetail',
                component: () => import('./pages/menu/accounting/jurnal_umum_d/v_read.vue') 
            },
            { 
                path: '/edit-jurnalumum-detail/:id',
                name:'editjurnalumumdetail/',
                component: () => import('./pages/menu/accounting/jurnal_umum_d/v_edit.vue') 
            },
            { 
                path: '/outgoing',
                name:'outgoingheader',
                component: () => import('./pages/menu/warehouse/outgoing_h/v_read.vue') 
            },
            { 
                path: '/add-outgoing',
                name:'addoutgoing',
                component: () => import('./pages/menu/warehouse/outgoing_h/v_add.vue') 
            },
            { 
                path: '/edit-outgoing/:id',
                name:'editoutgoing',
                component: () => import('./pages/menu/warehouse/outgoing_h/v_edit.vue') 
            },
            { 
                path: '/add-outgoingdetail/:id',
                name:'addoutgoingdetail',
                component: () => import('./pages/menu/warehouse/outgoing_d/v_add.vue') 
            },
            { 
                path: '/list-outgoingdetail/:id',
                name:'listoutgoingdetail',
                component: () => import('./pages/menu/warehouse/outgoing_d/v_read.vue') 
            },
            { 
                path: '/edit-outgoingdetail/:id',
                name:'editoutgoingdetail',
                component: () => import('./pages/menu/warehouse/outgoing_d/v_edit.vue') 
            },
            { 
                path: '/proses-produksi',
                name:'prosesproduksiheader',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi/v_read.vue') 
            },
            { 
                path: '/add-prosesproduksi/:id',
                name:'addprosesproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi/v_add.vue') 
            },
            { 
                path: '/edit-prosesproduksi/:id',
                name:'editproses-produksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi/v_edit.vue') 
            },
            { 
                path: '/edit-prosesproduksi-detailmaterial/:id',
                name:'editprosesproduksidetailmaterial',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_material/v_edit.vue') 
            },
            { 
                path: '/view-prosesproduksi-detailmaterial/:id',
                name:'viewprosesproduksidetailmaterial',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_material/v_read.vue') 
            },
            { 
                path: '/add-prosesproduksi-detailmaterial/:id',
                name:'addprosesproduksidetailmaterial',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_material/v_add.vue') 
            },
            { 
                path: '/view-stok-hasilproduksi/:id',
                name:'viewstokhasilproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_dari_proses_produksi/v_readstok.vue') 
            },
            { 
                path: '/add-prosesproduksi-detail-fromproduksi/:id/:noprod',
                name:'addprosesproduksidetailfromproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_dari_proses_produksi/v_add.vue') 
            },
            { 
                path: '/view-prosesproduksi-detailfrom-prosesproduksi/:id',
                name:'viewprosesproduksidetailfromprosesproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_dari_proses_produksi/v_read.vue') 
            },
            { 
                path: '/edit-prosesproduksi-detail-fromproduksi/:id',
                name:'editprosesproduksidetailfromproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d_dari_proses_produksi/v_edit.vue') 
            },
            { 
                path: '/finishgoodd',
                name:'finishgoodheaderr',
                component: () => import('./pages/menu/warehouse/finish_good/v_read.vue') 
            },
            { 
                path: '/add-finishgood/:id',
                name:'addfinishgood',
                component: () => import('./pages/menu/warehouse/finish_good/v_add.vue') 
            },
            { 
                path: '/edit-finishgood/:id',
                name:'editfinishgood',
                component: () => import('./pages/menu/warehouse/finish_good/v_edit.vue') 
            },
            { 
                path: '/add-finishgood-detail/:id/:nobc/:noreceipt',
                name:'addfinishgooddetail',
                component: () => import('./pages/menu/warehouse/finish_good_d_external/v_add.vue') 
            },
            { 
                path: '/list-finishgood-detail/:id',
                name:'listfinishgooddetail',
                component: () => import('./pages/menu/warehouse/finish_good_d_external/v_read.vue') 
            },
            { 
                path: '/edit-finishgood-detail/:id',
                name:'editfinishgooddetail',
                component: () => import('./pages/menu/warehouse/finish_good_d_external/v_edit.vue') 
            },
            { 
                path: '/add-finishgoodinternal-detail/:id',
                name:'addfinishgoodinternaldetail',
                component: () => import('./pages/menu/warehouse/finish_good_d_internal/v_add.vue') 
            },
            { 
                path: '/edit-detailfinishgood-dariprosesproduksi/:id',
                name:'editdetailfinishgooddariprosesproduksi',
                component: () => import('./pages/menu/warehouse/finish_good_d_internal/v_edit.vue') 
            },

            { 
                path: '/add-penerimaan',
                name:'addpenerimaanheader',
                component: () => import('./pages/menu/warehouse/penerimaan_h/v_addheader.vue') 
            },
            { 
                path: '/add-produkproses/:id',
                name:'addprodukprosesdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d/v_adddetail.vue') 
            },
            { 
                path: '/add-proses-produksi',
                name:'addprosesproduksidetail',
                // component: () => import('./pages/menu/proses_produksi/v_adddetail.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi/v_adddetail2.vue') 
            },
            { 
                path: '/add-bom',
                name:'addbom',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_d/v_adddetail.vue') 
            },
            { 
                path: '/produk-prosesbom/:id',
                name:'produkprosesbom',
                // component: () => import('./pages/menu/produk_proses_d_bom/v_readdetail.vue') 
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_readdetail2.vue') 
            },
            { 
                path: '/add-produkbom-proses',
                name:'addprodukbomproses',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_adddetail.vue') 
            },
            { 
                path: '/edit-prosesprodukbom-detail/:id',
                name:'editprosesprodukbomdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom/v_editdetail.vue') 
            },
            { 
                path: '/list-prosesprodukbommaterial-detail/:id',
                name:'listprosesprodukbommaterialdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom_material/v_read.vue') 
            },
            { 
                path: '/edit-prosesprodukbommaterial-detail/:id',
                name:'editprosesprodukbommaterialdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom_material/v_edit.vue') 
            },
            { 
                path: '/add-prosesprodukbommaterial-detail',
                name:'addprosesprodukbommaterialdetail',
                component: () => import('./pages/menu/product_dan_orderbuyer/produk_proses_d_bom_material/v_add.vue') 
            },
            { 
                path: '/jadwal-produksi',
                name:'jadwalproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/jadwal_produksi/v_read.vue') 
            },
            { 
                path: '/edit-jadwal-produksi/:id',
                name:'editjadwalproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/jadwal_produksi/v_edit.vue') 
            },
            { 
                path: '/request-material',
                name:'requestmaterial',
                // component: () => import('./pages/menu/request_material/v_read.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/request_material/v_readdetail.vue') 
            },
            { 
                path: '/edit-request-material/:id',
                name:'editrequestmaterial',
                component: () => import('./pages/menu/proses_produksi_menu/request_material/v_edit.vue') 
            },
            { 
                path: '/add-request-material/:noprod/:ordno',
                name:'addrequestmaterial',
                component: () => import('./pages/menu/proses_produksi_menu/request_material/v_add.vue') 
            },
            { 
                path: '/adddetail-prosesproduksi/:id',
                name:'adddetailprosesproduksi',
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi/v_simpan.vue') 
            },
            { 
                path: '/tracebility',
                // name:'tracebilitylistpacking',
                name:'tracebilitydata',
                // component: () => import('./pages/menu/tracebility/v_readpacking.vue') 
                component: () => import('./pages/menu/tracebility/v_readdata.vue') 
            },
            { 
                path: '/view-tracebility-packingbuyer/:id',
                name:'viewtracebilitypackingbuyer',
                component: () => import('./pages/menu/tracebility/v_readpackingfrombuyerorder.vue') 
            },
            { 
                path: '/viewproduksi-tanggalhasilpacking/:id',
                name:'viewproduksitanggalhasilpacking',
                component: () => import('./pages/menu/tracebility/v_readproduksitanggalhasilpacking.vue') 
            },
            { 
                path: '/view-informasibarang-outgoing/:id',
                name:'viewinformasibarangoutgoing',
                component: () => import('./pages/menu/tracebility/v_readbarangdarioutgoing.vue') 
            },
            { 
                path: '/finishgood',
                name:'finishgoodheader',
                component: () => import('./pages/menu/warehouse/finish_good/v_readdata.vue') 
                // component: () => import('./pages/menu/finish_good/v_readdata2.vue') 
            },
            { 
                path: '/report-wh-outgoing',
                name:'reportwhoutgoing',
                component: () => import('./pages/menu/reporting/report_wh_outgoing.vue') 
            },
            { 
                path: '/report-wh-receipt',
                name:'reportwhreceipt',
                component: () => import('./pages/menu/reporting/report_wh_receipt.vue') 
            },
            { 
                path: '/report-po',
                name:'reportpo',
                component: () => import('./pages/menu/reporting/report_po.vue') 
            },
            //update 27 Des 2021 09.28
            { 
                path: '/addrequest-material',
                name:'addrequestmaterial',
                // component: () => import('./pages/menu/request_material/v_read.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/request_material/v_addrequest.vue') 
            },
            { 
                path: '/scrap',
                name:'scrapdash',
                // component: () => import('./pages/menu/proses_produksi_menu/scrap/v_read.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/scrap/v_read2.vue') 
            },
            { 
                path: '/addscrap',
                name:'addscrapdash',
                // component: () => import('./pages/menu/proses_produksi_menu/scrap/v_add.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/scrap/v_add2.vue') 
            },
            { 
                path: '/user',
                name:'usermanagement',
                component: () => import('./pages/menu/management/user_management/v_read.vue') 
            },
            { 
                path: '/usergroup',
                name:'usergroupmanagement',
                component: () => import('./pages/menu/management/usergroup_management/v_read.vue') 
            },
            { 
                path: '/detailusergroup/:id',
                name:'usergroupdetailmanagement',
                component: () => import('./pages/menu/management/usergroup_management/v_detail.vue') 
            },
            { 
                path: '/usermenu',
                name:'usermenumanagement',
                component: () => import('./pages/menu/management/menu_management/v_read.vue') 
            },
            { 
                path: '/reporting-entry-items',
                name:'reportingentryitemsperbc',
                component: () => import('./pages/menu/reporting/report_pemasukan_barangperbc.vue') 
            },
            { 
                path: '/reporting-wipbalance',
                name:'reportingwipbalance',
                component: () => import('./pages/menu/reporting/report_wipbalance.vue') 
            },
            { 
                path: '/finishgood-in',
                name:'finishgoodin',
                component: () => import('./pages/menu/warehouse/finish_good_in/v_read.vue') 
            },
            { 
                path: '/finishgood-out',
                name:'finishgoodout',
                component: () => import('./pages/menu/warehouse/finish_good_out/v_read.vue') 
            },
            { 
                path: '/add-finishgood-out',
                name:'addfinishgoodout',
                component: () => import('./pages/menu/warehouse/finish_good_out/v_add.vue') 
            },
            { 
                path: '/edit-finishgood-out/:id',
                name:'editfinishgoodout',
                component: () => import('./pages/menu/warehouse/finish_good_out/v_edit.vue') 
            },
            { 
                path: '/detail-finishgood-out/:nomorpengeluaran',
                name:'detailfinishgoodout',
                component: () => import('./pages/menu/warehouse/finish_good_out_d/v_read.vue') 
            },
            { 
                path: '/unit',
                name:'unitmaster',
                component: () => import('./pages/menu/master_data/unit/v_read.vue') 
            },
            { 
                path: '/reporting-outitems',
                name:'reportingoutitems',
                component: () => import('./pages/menu/reporting/report_pengeluaran_barangperbc.vue') 
            },
            { 
                path: '/reporting-bahanbaku-penolong',
                name:'reportingbahanbakupenolong',
                component: () => import('./pages/menu/reporting/report_tanggungjawab_mutasibahanbaku_penolong.vue') 
            },
            { 
                path: '/reporting-bahanjadi',
                name:'reportingbahanjadi',
                component: () => import('./pages/menu/reporting/report_tanggungjawab_mutasibahanjadi.vue') 
            },
            { 
                path: '/reporting-mesin-alatkantor',
                name:'reportingmesinalatkantor',
                component: () => import('./pages/menu/reporting/report_tanggungjawab_mutasimesin_alatkantor.vue') 
            },
            { 
                path: '/req-from-bom',
                name:'requestfrombom',
                component: () => import('./pages/menu/purchase/request_from_bom/v_read.vue') 
            },
            { 
                path: '/scrapmaster',
                name:'scrap-master',
                component: () => import('./pages/menu/master_data/scrap/v_read.vue') 
            },
            { 
                path: '/reporting-mutasiscrap',
                name:'reportingmutasiscrap',
                component: () => import('./pages/menu/reporting/report_mutasi_scrap.vue') 
            },
            { 
                path: '/returitem-towh',
                name:'returitemtowh',
                component: () => import('./pages/menu/proses_produksi_menu/retur_barang_ke_wh/v_read.vue') 
            },
            { 
                path: '/add-returitem-towh',
                name:'addreturitemtowh',
                component: () => import('./pages/menu/proses_produksi_menu/retur_barang_ke_wh/v_add.vue') 
            },
            { 
                path: '/cashbankin',
                name:'cashbankinheader',
                // component: () => import('./pages/menu/accounting/cashbank_in_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/cashbank_in_h/v_read2.vue') 
            },
            { 
                path: '/edit-cashbankin/:id',
                name:'editcashbankheader',
                component: () => import('./pages/menu/accounting/cashbank_in_h/v_edit.vue') 
            },
            /* { 
                path: '/add-cashbank',
                name:'addcashbankheader',
                component: () => import('./pages/menu/accounting/cashbank_in_h/v_add.vue') 
            }, */
            { 
                path: '/add-cashbankin',
                name:'addcashbankinheader',
                component: () => import('./pages/menu/accounting/cashbank_in_h/v_add2.vue') 
            },
            { 
                path: '/detail-cashbankin/:nobukti',
                name:'detailcashbankinheader',
                component: () => import('./pages/menu/accounting/cashbank_in_d/v_read.vue') 
            },
            { 
                path: '/cashbankout',
                name:'cashbankoutheader',
                // component: () => import('./pages/menu/accounting/cashbank_out_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/cashbank_out_h/v_read2.vue') 
            },
            { 
                path: '/add-cashbankout',
                name:'addcashbankoutheader',
                // component: () => import('./pages/menu/accounting/cashbank_out_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/cashbank_out_h/v_add.vue') 
            },
            { 
                path: '/edit-cashbankout/:id',
                name:'editcashbankoutheader',
                component: () => import('./pages/menu/accounting/cashbank_out_h/v_edit.vue') 
            },
            { 
                path: '/detail-cashbankout/:nobukti',
                name:'detailcashbankoutheader',
                component: () => import('./pages/menu/accounting/cashbank_out_d/v_read.vue') 
            },
            { 
                path: '/accountpay',
                name:'accountpayheader',
                // component: () => import('./pages/menu/accounting/account_pay_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_pay_h/v_read2.vue') 
            },
            { 
                path: '/edit-accountpay/:id',
                name:'editaccountpayheader',
                // component: () => import('./pages/menu/accounting/account_pay_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_pay_h/v_edit.vue') 
            },
            { 
                path: '/add-accountpay',
                name:'addaccountpayheader',
                // component: () => import('./pages/menu/accounting/account_pay_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_pay_h/v_add.vue') 
            },
            { 
                path: '/detail-accountpay/:nobukti',
                name:'detailaccountpayheader',
                component: () => import('./pages/menu/accounting/account_pay_d/v_read.vue') 
            },
            { 
                path: '/accountreceiver',
                name:'accountreceiverheader',
                // component: () => import('./pages/menu/accounting/account_receiver_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_receiver_h/v_read2.vue') 
            },
            { 
                path: '/add-accountreceiver',
                name:'addaccountreceiverheader',
                // component: () => import('./pages/menu/accounting/account_receiver_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_receiver_h/v_add.vue') 
            },
            { 
                path: '/edit-accountreceiver/:id',
                name:'editaccountreceiverheader',
                // component: () => import('./pages/menu/accounting/account_receiver_h/v_read.vue') 
                component: () => import('./pages/menu/accounting/account_receiver_h/v_edit.vue') 
            },
            { 
                path: '/detail-accountreceiver/:nobukti',
                name:'detailaccountreceiverheader',
                component: () => import('./pages/menu/accounting/account_receiver_d/v_read.vue') 
            },
            { 
                path: '/purchase-request',
                name:'purchaserequesth',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/purchase/purchase_request/v_add.vue') 
            },
            { 
                path: '/konversi',
                name:'konversi',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/master_data/konversi/v_read.vue') 
            },
            { 
                path: '/proses-produksi-detail/:id',
                name:'prosesproduksidetail',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/proses_produksi_menu/proses_produksi_d/v_readdetail.vue') 
            },
            // update 20 Mei 2022
            { 
                path: '/report-trial-balance',
                name:'reportbalancetrial',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/reporting/report_trial_balance.vue') 
            },
            { 
                path: '/report-profit-loss',
                name:'reportprofitloss',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/reporting/report_profit_loss.vue') 
            },
            { 
                path: '/report-neraca-periode',
                name:'reportneracaperiode',
                // component: () => import('./pages/menu/purchase/purchase_request/v_read.vue') 
                component: () => import('./pages/menu/reporting/report_neraca_periode.vue') 
            },

            // { 
            //     path: '/addpurchase-request',
            //     name:'addpurchaserequesth',
            //     component: () => import('./pages/menu/purchase/purchase_request/v_add.vue') 
            // },
        ]
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router