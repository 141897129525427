<template>
  <div>
        <router-view></router-view>
    </div>
</template>

<script>
var axios = require("axios");
export default {
  name: "app",
  methods: {
    logout: function () {
      sessionStorage.removeItem("token");
      delete axios.defaults.headers.common["key"];
      window.location.href = "/";
    },
  },
};
</script>